import React from 'react';
import { FormattedMessage, defineMessages, useIntl } from 'react-intl-next';

import { Inline, Text } from '@atlaskit/primitives';
import PersonIcon from '@atlaskit/icon/core/person';
import PeopleGroupIcon from '@atlaskit/icon/core/people-group';
import { token } from '@atlaskit/tokens';

const i18n = defineMessages({
	segmentationMessageRestricted: {
		id: 'custom-sites-extensions.link-cards.link-cards-content.segmentation-message.restricted',
		defaultMessage: 'Relevant to you',
		description: 'The content is only viewable to the user and not to everyone',
	},
	segmentationMessageNonRestricted: {
		id: 'custom-sites-extensions.link-cards.link-cards-content.segmentation-message.non-restricted',
		defaultMessage: 'Viewable to everyone',
		description: 'The content is only viewable to everyone',
	},
});

type SegmentationMessageProps = {
	isRestricted?: boolean;
};

export const SegmentationMessage: React.FC<SegmentationMessageProps> = ({ isRestricted }) => {
	const { formatMessage } = useIntl();

	return (
		<Inline space="space.050" alignInline="start" alignBlock="center">
			{isRestricted ? (
				<PersonIcon
					label={formatMessage(i18n.segmentationMessageRestricted)}
					color={token('color.icon.subtle')}
				/>
			) : (
				<PeopleGroupIcon
					label={formatMessage(i18n.segmentationMessageNonRestricted)}
					color={token('color.icon.subtle')}
				/>
			)}
			<Text size="small" color="color.text.subtlest">
				<FormattedMessage
					{...(isRestricted
						? i18n.segmentationMessageRestricted
						: i18n.segmentationMessageNonRestricted)}
				/>
			</Text>
		</Inline>
	);
};
